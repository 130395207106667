






































































import Vue from "vue";
import Component from "vue-class-component";
import AppLayout from "../components/commons/layouts/AppLayout.vue";
import { Ticket } from "../types/gqlGeneratedPrivate";
import BackButton from "../components/commons/inputsAndControls/BackButton.vue";
import Button from "../components/commons/inputsAndControls/Button.vue";
import SvgIcon from "../components/app/SvgIcon.vue";
import { apiClient } from "../utils";
import { api } from "../api";
import TicketHeader from "../components/app/ticket/TicketHeader.vue";
import DateField from "../components/commons/inputsAndControls/DateField.vue";
import TicketContact from "../components/app/ticket/TicketContact.vue";
import Table from "../components/commons/tables/Table.vue";
import TableHeader from "../components/commons/tables/TableHeader.vue";
import TicketTable from "../components/app/ticket/TicketTable.vue";
import Timeline from "../components/commons/Timeline.vue";
import Card from "../components/commons/Card.vue";
import TicketChatItem from "../components/app/ticket/TicketChatItem.vue";
import TicketGallery from "../components/app/ticket/TicketGallery.vue";
import TicketChat from "../components/app/ticket/TicketChat.vue";
import { eventBus } from "../utils/eventBus";
import { EventBus } from "../enums";

@Component({
  components: {
    TicketChat,
    TicketGallery,
    TicketChatItem,
    Card,
    Timeline,
    TicketTable,
    TableHeader,
    Table,
    TicketContact,
    DateField,
    TicketHeader,
    SvgIcon,
    Button,
    BackButton,
    AppLayout,
  },
})
export default class TicketPage extends Vue {
  ticket: Ticket | null = null;

  protected refreshInterval = 30;
  protected refresher?: number;

  constructor() {
    super();
    this.setRefreshInterval();
  }

  async fetchTicket(payload: { id: number }): Promise<Ticket | undefined> {
    const { data } = await apiClient.callGraphqlPrivate<
      { ticket: Ticket },
      { id: number }
    >({
      ...api.ticket.fetchTicket,
      variables: {
        id: payload.id,
      },
    });
    return data?.ticket;
  }

  async refreshData() {
    const ticket = await this.fetchTicket({
      id: parseInt(this.$route.params.id),
    });
    if (ticket) {
      this.ticket = ticket;
    }
  }

  protected setRefreshInterval(): void {
    // Vue is somehow losing that value, getting it back as Undefined
    // if (!this.refreshInterval) return;

    this.refresher = setInterval(() => {
      this.refreshData();
    }, (this.refreshInterval || 30) * 1000);
  }

  public beforeDestroy(): void {
    if (this.refresher) clearInterval(this.refresher);
  }

  async created() {
    const ticket = await this.fetchTicket({
      id: parseInt(this.$route.params.id),
    });
    if (ticket) {
      this.ticket = ticket;
    }

    eventBus.$on(EventBus.TicketRefreshDetail, () => {
      this.refreshData();
    });
  }
}
