










































































import Vue from "vue";
import Component from "vue-class-component";
import {
  MakeTicketImportantMutation,
  MakeTicketImportantMutationVariables,
  MakeTicketNotImportantMutation,
  MakeTicketNotImportantMutationVariables,
  MakeTicketProblemMutation,
  MakeTicketProblemMutationVariables,
  PinTicketMutation,
  PinTicketMutationVariables,
  SolveTicketProblemMutation,
  SolveTicketProblemMutationVariables,
  Ticket,
  UnpinTicketMutation,
  UnpinTicketMutationVariables,
} from "../../../types/gqlGeneratedPrivate";
import { Prop } from "vue-property-decorator";
import Button from "../../commons/inputsAndControls/Button.vue";
import { toLocalDateTime } from "../../../filters/datetime";
import { alerts, apiClient } from "../../../utils";
import { api } from "../../../api";
import { Routes } from "../../../enums/Routes";

@Component({
  components: {
    Button,
  },
  filters: {
    toLocalDateTime,
  },
})
export default class TicketHeader extends Vue {
  Routes = Routes;

  @Prop({ type: Object, required: true })
  ticket!: Ticket;

  async solveProblem() {
    const { data } = await apiClient.callGraphqlPrivate<
      SolveTicketProblemMutation,
      SolveTicketProblemMutationVariables
    >({
      ...api.ticket.solveTicketProblem,
      variables: {
        id: this.ticket.id,
      },
    });
    if (data?.solveTicketProblem) {
      alerts.addSuccessAlert("TICKET_SOLVE_PROBLEM", "Vyřešit problém");
      this.$emit("refreshData");
    }
  }

  async makeProblem() {
    const { data } = await apiClient.callGraphqlPrivate<
      MakeTicketProblemMutation,
      MakeTicketProblemMutationVariables
    >({
      ...api.ticket.makeTicketProblem,
      variables: {
        id: this.ticket.id,
      },
    });
    if (data?.makeTicketProblem) {
      alerts.addSuccessAlert("TICKET_MAKE_PROBLEM", "Vytořit problém");
      this.$emit("refreshData");
    }
  }

  async pinTicket(): Promise<void> {
    const { data } = await apiClient.callGraphqlPrivate<
      PinTicketMutation,
      PinTicketMutationVariables
    >({
      ...api.ticket.pin,
      variables: {
        id: this.ticket.id,
      },
    });
    if (data?.pinTicket) {
      alerts.addSuccessAlert("TICKET_PIN", "Zakázka připnuta");
      this.$emit("refreshData");
    }
  }

  async unpinTicket(): Promise<void> {
    const { data } = await apiClient.callGraphqlPrivate<
      UnpinTicketMutation,
      UnpinTicketMutationVariables
    >({
      ...api.ticket.unpin,
      variables: {
        id: this.ticket.id,
      },
    });
    if (data?.unpinTicket) {
      alerts.addSuccessAlert("TICKET_UNPIN", "Zakázka odepnuta");
      this.$emit("refreshData");
    }
  }

  async makeImportant(): Promise<void> {
    const { data } = await apiClient.callGraphqlPrivate<
      MakeTicketImportantMutation,
      MakeTicketImportantMutationVariables
    >({
      ...api.ticket.makeImportant,
      variables: {
        id: this.ticket.id,
      },
    });
    if (data?.makeTicketImportant) {
      alerts.addSuccessAlert(
        "TICKET_MAKE_IMPORTANT",
        "Zakázka označena za důležitou"
      );
      this.$emit("refreshData");
    }
  }

  async makeUnimportant(): Promise<void> {
    const { data } = await apiClient.callGraphqlPrivate<
      MakeTicketNotImportantMutation,
      MakeTicketNotImportantMutationVariables
    >({
      ...api.ticket.makeNotImportant,
      variables: {
        id: this.ticket.id,
      },
    });
    if (data?.makeTicketNotImportant) {
      alerts.addSuccessAlert(
        "TICKET_MAKE_NOT_IMPORTANT",
        "Označení zakázky za důležitou bylo odebráno"
      );
      this.$emit("refreshData");
    }
  }
}
