






























































































import Vue from "vue";
import Component from "vue-class-component";
import {
  ChangeDeadlineMutation,
  ChangeDeadlineMutationVariables,
  Operation,
  OperationStatusEnum,
  Ticket,
} from "../../../types/gqlGeneratedPrivate";
import { Prop } from "vue-property-decorator";
import DateField from "../../commons/inputsAndControls/DateField.vue";
import { formatRangeOfDates } from "../../../filters/datetime";
import { alerts, apiClient } from "../../../utils";
import { api } from "../../../api";

@Component({
  components: { DateField },
})
export default class TicketContact extends Vue {
  @Prop({ type: Object, required: true })
  private ticket!: Ticket;

  operationDateMenu = false;
  operationDatesProblem = false;

  get hasConflict(): boolean {
    return this.ticket?.operations?.[0]?.conflict;
  }

  get formattedOperationDates() {
    const dateCreated = new Date(this.ticket.created);
    if (dateCreated > new Date(this.ticket.process.deadline)) {
      this.operationDatesProblem = true;
      return "Deadline je před založením zakázky!";
    }

    if (dateCreated > new Date(this.ticket.operations[0].estimateFrom ?? "")) {
      this.operationDatesProblem = true;
      return "První operace je naplánovaná před založením zakázky!";
    }

    const currentDate = new Date().toISOString();
    if (
      this.ticket.operations.filter((operation) => {
        return (
          operation.status === "todo" && currentDate > operation.estimateFrom
        );
      }).length
    ) {
      this.operationDatesProblem = true;
      return (
        "Začátek jedné nebo víc operací, na kterých se ještě nezačalo pracovat je v minulosti: " +
        formatRangeOfDates(this.ticket.created, this.ticket.process.deadline)
      );
    }

    this.operationDatesProblem = false;

    return formatRangeOfDates(
      this.ticket.created,
      this.ticket.process.deadline
    );
  }

  get hasOperationDates(): boolean {
    return this.ticket.created && this.ticket.process?.deadline;
  }

  get deadline(): string {
    // Get only date from ISO format
    return this.ticket.process.deadline.substring(0, 10);
  }

  async handleInput(input: string) {
    await this.changeDeadline(input);
    this.operationDateMenu = !this.operationDateMenu;
  }

  async changeDeadline(deadline: string): Promise<void> {
    const { data } = await apiClient.callGraphqlPrivate<
      ChangeDeadlineMutation,
      ChangeDeadlineMutationVariables
    >({
      ...api.ticket.changeDeadline,
      variables: {
        input: {
          ticketId: this.ticket.id,
          deadLine: deadline,
        },
      },
    });
    if (data?.changeDeadline) {
      alerts.addSuccessAlert("CHANGE DEADLINE", "Termín změněn");
      this.ticket.process.deadline = deadline;
    }
    this.$emit("changeDeadline");
  }

  get contactItems() {
    return {
      ["ODBĚRATEL"]: this.ticket.doctor.companyName,
      ["POBOČKA"]: "CHIRONAX Dental labor s.r.o.",
      ["PACIENT"]: `${this.ticket.patient?.firstname} ${this.ticket.patient?.surname}`,
      ["DOKTOR"]: `${this.ticket.doctor?.fullName}`,
      ["STANOVIŠTĚ"]: `${this.currentOperationLaborer(this.ticket.operations)}`,
      ["HL. LABORANT"]: `${this.ticket.laborer?.firstname} ${this.ticket.laborer?.surname}`,
    };
  }

  allowedDates(val: string) {
    const day = new Date(val).getDay();
    return day !== 0 && day !== 6;
  }

  currentOperationLaborer(operations: Operation[]): string {
    const reverseOperations = operations.slice().reverse();
    let operation = reverseOperations.find(
      (o) => o.status === OperationStatusEnum.InProgress
    );
    if (!operation) {
      operation = reverseOperations.find(
        (o) => o.status === OperationStatusEnum.Done
      );
    }

    if (operation) {
      const firstname = operation.laborer.firstname;
      const surname = operation.laborer.surname;
      return `${firstname} ${surname}`;
    }
    return "";
  }
}
