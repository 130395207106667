






























































import { Component, Prop, Vue } from "vue-property-decorator";
import { toLocalDateTime } from "../../../filters/datetime";
import { DateTime } from "luxon";
import { alerts, apiClient } from "../../../utils";
import {
  Operation,
  UpdateOperationDirectionEnum,
} from "../../../types/gqlGeneratedPrivate";
import { api } from "../../../api";
import Button from "../../commons/inputsAndControls/Button.vue";
import SelectBox from "./../../commons/inputsAndControls/SelectBox.vue";
import RoundButton from "../../commons/inputsAndControls/RoundButton.vue";
import { eventBus } from "../../../utils/eventBus";
import { EventBus } from "../../../enums";

interface SelectionType {
  date: string;
  full: boolean;
  laborer: number;
}

@Component({
  components: { RoundButton, Button, SelectBox },
  filters: {
    toLocalDateTime,
  },
})
export default class TimelineChangeTimeCard extends Vue {
  @Prop({ required: true, type: Object })
  operation!: Operation;

  @Prop({ required: true, type: Array })
  laborers!: Array<{ text: string; value: number }>;

  saving = false;
  selection: SelectionType = {} as SelectionType;

  created() {
    this.selection = {
      laborer: this.operation.laborerId,
      date: this.operation.estimateTo,
      full: false,
    };
  }

  isLaborerChangeable(): boolean {
    return (
      this.laborers.filter(({ value }) => value === this.selection.laborer)
        .length !== 0
    );
  }

  plusDay(): void {
    this.checkDayAvailability(
      DateTime.fromISO(this.selection.date).plus({ days: 1 }).toString(),
      UpdateOperationDirectionEnum.More
    );
  }

  minusDay(): void {
    this.checkDayAvailability(
      DateTime.fromISO(this.selection.date).plus({ days: -1 }).toString(),
      UpdateOperationDirectionEnum.Less
    );
  }

  async checkDayAvailability(
    date: string,
    direction: UpdateOperationDirectionEnum
  ): Promise<void> {
    const { data } = await apiClient.callGraphqlPrivate({
      ...api.operationTemplates.checkUpdateOperation,
      variables: {
        id: this.operation.id,
        input: {
          estimateTo: date,
          direction,
        },
      },
    });

    this.selection = {
      ...this.selection,
      date: data.checkUpdateOperation.estimateTo,
      full: !data.checkUpdateOperation.isPossible,
    };
  }

  closeModal(): void {
    this.$emit("close");
  }

  async saveChanges(): Promise<void> {
    this.saving = true;

    const { data } = await apiClient.callGraphqlPrivate({
      ...api.operationTemplates.updateOperation,
      variables: {
        id: this.operation.id,
        input: {
          laborerId: this.selection.laborer,
          estimateTo: this.selection.date,
        },
      },
    });

    this.saving = false;

    if (data.updateOperation?.id) {
      alerts.addSuccessAlert("CHANGE_DATE", "Uloženo");

      eventBus.$emit(EventBus.TicketRefreshDetail);
      this.closeModal();
    }
  }

  toLocalDateTime = toLocalDateTime;
}
