


















































import { Component, Prop, Vue } from "vue-property-decorator";
import {
  DeviceFormFragment,
  LaborerListQuery,
  LaborerListQueryVariables,
  Operation,
  OperationStatusEnum,
} from "../../types/gqlGeneratedPrivate";
import { formatRangeOfDates, toLocalDateTime } from "../../filters/datetime";
import Button from "./inputsAndControls/Button.vue";
import { apiClient } from "../../utils";
import { api } from "../../api";
import TimelineChangeTimeCard from "../app/timeline/TimelineChangeTimeCard.vue";

@Component({
  components: { TimelineChangeTimeCard, Button },
  filters: {
    toLocalDateTime,
  },
})
export default class Timeline extends Vue {
  @Prop({ required: true, type: Array })
  operations!: [Operation];

  openedCard: number | null = null;
  laborers: Array<{ text: string; value: number }> = [];
  value: DeviceFormFragment | null = null;

  formattedRange(operation: Operation) {
    return formatRangeOfDates(operation.realFrom, operation.realTo);
  }

  isStatusTodo(operation: Operation) {
    return operation.status?.toUpperCase() === "TODO";
  }

  isStatusDone(operation: Operation) {
    return operation.status?.toUpperCase() === "DONE";
  }

  operationData(operationStatus: string) {
    switch (operationStatus) {
      case OperationStatusEnum.Done:
        return {
          icon: "check",
          color: "success",
          class: "gray--text",
          iconColor: "white",
        };
      case OperationStatusEnum.InProgress:
        return {
          icon: "mdi-progress-clock",
          color: "orange",
          class: "",
          iconColor: "white",
        };
      case OperationStatusEnum.Todo:
        return {
          icon: "mdi-help",
          color: "white",
          class: "gray--text",
          iconColor: "black",
        };
      case OperationStatusEnum.Handover:
        return {
          icon: "mdi-barcode-scan",
          color: "white",
          class: "gray--text",
          iconColor: "black",
        };
      case OperationStatusEnum.Returned:
        return {
          icon: "mdi-backspace",
          color: "white",
          class: "gray--text",
          iconColor: "black",
        };
      default:
        return {
          icon: "mdi-help",
          color: "gray",
          class: "gray--text",
          iconColor: "black",
        };
    }
  }

  async created() {
    const { data } = await apiClient.callGraphqlPrivate<
      LaborerListQuery,
      LaborerListQueryVariables
    >({
      ...api.laborers.laborerList,
      variables: {
        input: {
          pager: {
            page: 1,
            size: 999,
          },
        },
      },
    });
    if (data?.laborers) {
      this.laborers = data?.laborers.items.map((item) => ({
        value: item.id,
        text: `${item.firstname} ${item.surname}`,
      }));
    }
  }

  showModal(id: number, status: string): void {
    if (status.toUpperCase() !== "DONE") {
      this.openedCard = id;
    }
  }

  closeModal(): void {
    this.openedCard = null;
  }
}
