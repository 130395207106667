
































import Vue from "vue";
import Component from "vue-class-component";
import DateField from "../../commons/inputsAndControls/DateField.vue";
import { toLocalDateTime } from "../../../filters/datetime";
import Button from "../../commons/inputsAndControls/Button.vue";
import { alerts } from "../../../utils";
@Component({
  components: { Button, DateField },
  filters: {
    toLocalDateTime,
  },
})
export default class TicketHeader extends Vue {
  deletePhoto() {
    // TODO implement function when be ready ND-300
    alerts.addSuccessAlert("CREATE_ADMIN", "Smazat fotku");
  }

  sharePhoto() {
    // TODO implement function when be ready ND-300
    alerts.addSuccessAlert("CREATE_ADMIN", "Sdilet fotku");
  }
}
