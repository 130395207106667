


























import Vue from "vue";
import Component from "vue-class-component";
import TicketChatItem from "./TicketChatItem.vue";
import { Prop } from "vue-property-decorator";
import { Chat } from "../../../types/gqlGeneratedPrivate";

@Component({
  components: {
    TicketChatItem,
  },
})
export default class TicketChat extends Vue {
  @Prop({ type: Array, required: true })
  externalChat!: Chat[];

  @Prop({ type: Array, required: true })
  internalChat!: Chat[];

  tab = "";
  tabs = ["Chat", "Odběratel"];
}
