






















import { Component, Prop, Vue } from "vue-property-decorator";
import { userSettings } from "../../utils/userSettings";

@Component
export default class Card extends Vue {
  @Prop({ required: false, type: Boolean, default: false })
  private loading!: boolean;

  @Prop({ required: false, type: String, default: null })
  private height?: string;

  @Prop({ required: false, type: String })
  private expandableCardId?: string;

  private expanded = true;

  constructor() {
    super();
    if (this.expandableCardId) {
      this.expanded = userSettings.getCardExpanded(this.expandableCardId);
    }
  }

  private handleExpand(): void {
    if (!this.expandableCardId) return;
    this.expanded = !this.expanded;
    userSettings.updateCardExpanded(this.expandableCardId, this.expanded);
  }
}
