























import { DateTime } from "luxon";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import DateField from "../../commons/inputsAndControls/DateField.vue";
import { toLocalDateTime } from "../../../filters/datetime";
import { Chat } from "../../../types/gqlGeneratedPrivate";

@Component({
  components: { DateField },
  filters: {
    toLocalDateTime,
  },
})
export default class TicketChatItem extends Vue {
  @Prop({ type: Object, required: true })
  chatItem!: Chat;

  formatPastDay(date: string): string {
    const startDate = DateTime.fromISO(date);
    const daysDiff = startDate.diffNow().as("day");
    const wholeDaysDiff = Math.round(daysDiff);
    return new Intl.RelativeTimeFormat("cs").format(wholeDaysDiff, "days");
  }
}
