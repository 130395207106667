















import Vue from "vue";
import Component from "vue-class-component";
import { Macro } from "../../../types/gqlGeneratedPrivate";
import Table from "../../commons/tables/Table.vue";
import { Prop } from "vue-property-decorator";
@Component({
  components: { Table },
})
export default class TicketTable extends Vue {
  @Prop({ type: Array, default: () => [] })
  items!: [Macro];

  headers = [
    {
      text: "Kód",
      sortable: true,
      align: "start",
      value: "subCode",
    },
    {
      text: "Název",
      sortable: true,
      align: "start",
      value: "name",
    },
    {
      text: "Počet",
      sortable: true,
      align: "start",
      value: "items",
    },
    {
      text: "Jednotková Cena",
      sortable: true,
      value: "price",
    },
    {
      text: "Celková Cena",
      sortable: true,
      value: "totalPrice",
    },
  ];
}
